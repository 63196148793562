import { Modal } from "antd";
import { GrCopy } from "react-icons/gr";
import { copyToClipboard } from "../../utils";

const ViewWritingModal = ({ isModalOpen, setIsModalOpen, data }) => {
  console.log("data", data);
  const text =
    data?.history?.essay_self_edited ||
    data?.self_editing ||
    data?.history?.essay_original;
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      title={`${data?.course_name} - ${data?.lesson_name}`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1100}
      footer={null}>
      <div className=" rounded-md overflow-hidden">
        {text && (
          <>
            <div className="w-full h-10 bg-gray-200 flex justify-end">
              <button
                onClick={() => {
                  copyToClipboard(text);
                }}
                className="flex items-center gap-2 mr-2">
                <GrCopy size={24} /> Copy
              </button>
            </div>
            <p className="text-base bg-blue-50 p-4">{text}</p>
          </>
        )}
        {!text && (
          <p className="text-base bg-blue-50 p-4">
            The student has not completed self editing section.
          </p>
        )}
      </div>
    </Modal>
  );
};
export default ViewWritingModal;
